<template>
    <div>
        <el-dialog class="cus_dialog " title="巡访详情" :visible.sync="dialogshow" :close-on-click-modal="false"
            @close="closeDialog" width="90%" height="90%">
            <el-table :data="DataList" row-key="id" border stripe>
                <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
                <el-table-column label="姓名" prop="stu_name" width="100" :sortable="true" align="center"></el-table-column>
                <el-table-column label="所属系部" prop="xibu_name" width="130" align="center"></el-table-column>
                <el-table-column label="所属班级" prop="class_name" width="100" align="center"></el-table-column>
                <el-table-column label="巡访人员" prop="tch_name" width="100" align="center"></el-table-column>
                <el-table-column label="企业名称" prop="enter_name" width="150" :sortable="true" align="center">
                
                </el-table-column>
                <el-table-column label="巡访时间" prop="xf_date" width="180" :sortable="true" align="center"></el-table-column>
                <el-table-column label="职位" prop="job" width="150" align="center"></el-table-column>
                <el-table-column label="巡访情况" prop="xf_qingkuang" width="100" align="center"></el-table-column>
                <el-table-column label="巡访地址" prop="xf_dizhi" width="150" align="center">
                    <template slot-scope="scope">
                        <div class="xiaojie">
                            {{ scope.row.xf_dizhi }}
                        </div>
                    </template>

                </el-table-column>
                <el-table-column label="巡访小结" prop="xf_xiaojie" min-width="150" align="center">
                    <template slot-scope="scope">
                        <div class="xiaojie">
                            {{ scope.row.xf_xiaojie }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button v-if="$store.state.pmid>1" @click="deleteRow(scope.row)" type="text" size="small">删除</el-button>
                        <el-button @click="viewRow(scope.row)" type="text" size="small">查看</el-button>
                    </template>
                </el-table-column>

            </el-table>

        </el-dialog>


        <el-dialog class="cus_dialog " title="巡访详情" :visible.sync="dialogshow2" :close-on-click-modal="false"
            @close="closeDialog2" width="60%" height="90%">

            <table class="dttable">
                <tr>
                    <td class="tdheader">巡访人员</td>
                    <td>{{ ckdItem.tch_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">巡访人员手机号</td>
                    <td>{{ ckdItem.tch_phone }}</td>
                </tr>
                <tr>
                    <td class="tdheader">巡访学生</td>
                    <td>{{ ckdItem.stu_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">所属系部</td>
                    <td>{{ ckdItem.xibu_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">所属班级</td>
                    <td>{{ ckdItem.class_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">公司名称</td>
                    <td>{{ ckdItem.enter_name }}</td>
                </tr>
                <tr>
                    <td class="tdheader">学生岗位</td>
                    <td>{{ ckdItem.job }}</td>
                </tr>
                <tr>
                    <td class="tdheader">巡访时间</td>
                    <td>{{ ckdItem.xf_date }}</td>
                </tr>
                <tr>
                    <td class="tdheader">巡访地址</td>
                    <td>{{ ckdItem.xf_dizhi }}</td>
                </tr>
                <tr>
                    <td class="tdheader">巡访情况</td>
                    <td>{{ ckdItem.xf_qingkuang }}</td>
                </tr>
                <tr>
                    <td class="tdheader">巡访小结</td>
                    <td>{{ ckdItem.xf_xiaojie }}</td>
                </tr>
                <tr>
                    <td class="tdheader">巡访照片</td>
                    <td>
                        <el-image v-for="(item,idx) in ckdItem.files" :key="idx" 
                                style="width: 100px; height: 100px" :src="item.fileUrl"  
                                :preview-src-list="[item.fileUrl]" fit="cover">
                        </el-image>
                   
                    </td>
                </tr>

            </table>



        </el-dialog>


    </div>
</template>

<script>

export default {
    props: ["xunfang"],
    components: {

    },
    data() {
        return {
            dialogshow: true,
            saveBtnShow: false,
            dialogshow2: false,
            DataList: [],
            ckdItem: {}
        }
    },
    mounted() {

        this.getList()
    },
    methods: {
        getList() {
            let xunfang = JSON.parse(JSON.stringify(this.xunfang))
            xunfang.keyword = xunfang.tch_name;
            xunfang.pagesize = 500
            this.$http.post("/api/xunfang_list", xunfang).then(res => {
                this.DataList = res.data.data
            })
        },
        closeDialog() {

            this.$$parent(this, "dialogShow", false)

        },
        deleteRow(e) {

            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post("/api/xunfang_delete", { id: e.id }).then(res => {
                    this.getList()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.$$parent(this, "getDataList")
                })
            }).catch(() => {

            });
        },
        viewRow(e) {
            try {
                e.files = JSON.parse(e.files)
            } catch (error) {
                
            }
            this.ckdItem = e;
            this.dialogshow2 = true
        },
        closeDialog2() {

            this.dialogShow2 = false

        },



    }

}

</script>

<style scoped>
.xiaojie {
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
</style>