<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right">


				<el-cascader ref="firstCascader" :options="TreeData" :props="defaultProps" :show-all-levels="true"
					v-model="searchForm.class_name" @change="getSelectType" clearable placeholder="请选择" filterable
					style="width: 250px;margin-right:10px" size="small">
				</el-cascader>

				<el-input placeholder="关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
					style="width:150px;margin-left: 10px" clearable></el-input>


				<el-button icon="el-icon-search" @click="getDataList" style="margin-left:10px" size="small"
					type="primary">搜索</el-button>
				<el-button v-if="TargetPmid" icon="el-icon-refresh" style="margin-left:10px" size="small"
					@click="tongbuData">同步数据</el-button>

				<el-button icon="el-icon-upload" style="margin-left:10px" size="small"
					@click="dialogshow1 = true">导入巡访</el-button>





			</div>
			<div style="padding-top: 14px">
				<div style=" width: 200px;  margin-top:-10px;">
                    <div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
                        <img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
                    </div>
                    <div style="margin-left: 50px; color:#535353; font-size:20px !important">巡访列表</div>
                </div>
				
			</div>
		</div>
		<div class="bcontent">


			<div>
				<el-table :data="tableData" row-key="id" header-row-class-name="tableheader" cell-class-name="celltd" border
					stripe>
					<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
					<el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column>
					<el-table-column label="所属系部" prop="xibu_name" width="150" align="center"></el-table-column>
					<el-table-column label="所属班级" prop="class_name" width="150" align="center"></el-table-column>
					<el-table-column label="巡访人员" prop="tch_name" min-width="150" align="center"></el-table-column>
					<el-table-column label="联系方式" prop="tch_phone" width="150" align="center"></el-table-column>
					<el-table-column label="累计巡访次数" prop="n" width="150" align="center"></el-table-column>

					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="viewInfo(scope.row)" type="text" size="small">查看</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div style="text-align:right;padding-top: 10px;">
					<el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
						:page-size="page.per_page" :total="page.count" layout="total, prev, pager, next"></el-pagination>
				</div>


			</div>

		</div>
		<xunfanginfo v-if="dialogShow" :xunfang="xunfang"></xunfanginfo>


		<el-dialog class="cus_dialog " title="巡访导入" :visible.sync="dialogshow1" :close-on-click-modal="false" width="600px"
			height="30%">
			<div style="line-height: 50px;">
				<a href="/xls/巡访信息导入模板.xlsx" target="_blank">巡访信息导入模板下载（excel）</a>
			</div>

			<div style="line-height: 50px;">
				<a href="/xls/导入巡访模板.docx" target="_blank">巡访信息导入模板下载（word）</a>
			</div>




			<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
				:on-success="uploadFiles" :show-file-list="false" name="image">
				<el-button icon="el-icon-upload" type="primary" style="margin-left:100px" size="small">导入巡访</el-button>
			</el-upload>

		</el-dialog>

	</div>
</template>

<script>
import xunfanginfo from './com/xunfangInfo.vue'

export default {
	components: {
		xunfanginfo


	},
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			searchForm: {
				class_name: "",
				keyword: ""

			},
			TargetPmid: 0,
			tableData: [],
			TreeData: [],
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
				total_page: 0
			},
			defaultProps: {
				label: 'name',
				value: 'name',
				children: 'children',
				emitPath: false,
				checkStrictly: true,

			},
			xunfang: null
		}
	},
	mounted() {
		this.getTargetPmid()
		this.getDataList()
		this.getTree()

	},
	methods: {
		getTargetPmid() {
			this.$http.post("/api/sch_qsx_target").then(res => {
				if (res.data && res.data.qsx_pmid) {
					this.TargetPmid = res.data.qsx_pmid
				}
			})

		},
		getTree() {
			this.$http.post("/api/xunfang_grade_tree").then(res => {
				this.TreeData = res.data
			})
		},
		getSelectType(e) {
			let node = this.$refs['firstCascader'].getCheckedNodes()
			if (node[0]) {
				let path = node[0].pathNodes[0].label
				if (node[0].pathNodes.length >= 2) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = node[0].pathNodes[1].label
				} else if (node[0].pathNodes.length >= 1) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = ""
				} else {
					this.searchForm.xibu_name = ""
					this.searchForm.class_name = ""
				}
			} else {
				this.searchForm.xibu_name = ""
				this.searchForm.class_name = ""

			}

		},
		tongbuData() {
			this.$http.post("/api/tongbu_xunfang", { loading: true, tpmid: this.TargetPmid }).then(res => {
				this.$message.success("同步成功")
				this.page.current_page = 1;
				this.getDataList();
			})
		},
		getDataList() {
			let data = {
				page: this.page.current_page,
				keyword: this.searchForm.keyword,
				xibu_name: this.searchForm.xibu_name,
				class_name: this.searchForm.class_name,
				showloading: true
			}
			this.$http.post("/api/xunfang_group_list", data).then(res => {
				this.tableData = res.data.data
				this.page = res.data.page
			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.xunfang = e

			this.dialogShow = true
		},
		uploadFiles(e) {
			if (e.src.indexOf("doc") > 0) {

				this.$http.post("/api/import_xunfang_word", {
					url: e.src
				}).then(res => {

					this.getDataList()
					this.$message.success("导入成功")
					if (res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" +msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogshow1 = false


				})

			} else {
				this.$http.post("/api/import_xunfangxls", {
					url: e.src
				}).then(res => {

					this.getDataList()
					this.$message.success("导入成功")
					if (res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg['巡访学生'] + ":" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogshow1 = false


				})
			}
		},

	}
}
</script>
<style scoped></style>
